<template>
  <div class="contentSuiviRegieRecap">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Dépôt </span>
          </div>

          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filterNumeroDepot"
            :options="[{ value: null, text: 'Tous' }, ...computedDepot]"
            text-field="text"
            value-field="value"
            @change="handleChangeNumDepot"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style  p-4-5">
            <span class="title-tabel">Filiale</span>
          </div>

          <SelectComponent
            :options="computedgetAllDepotRecapRegieTh"
            :value="filteDepot"
            label="name"
            champName="dépot"
            filterName="filteDepot"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-1  bg-select-vue"
          />
        </div>
        <div v-if="getLoadingRecapRegieTh" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            v-if="
              cantExport(this.$options.name) &&
                computedGetSuiviRegie &&
                computedGetSuiviRegie.length > 0
            "
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table-simple
          id="my-table"
          class="custom-table-style tableRecapRegie"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          v-if="computedGetSuiviRegie"
        >
          <b-thead>
            <b-tr>
              <b-th class="th-rouge-bg-color th-border-raduis-left color-white"
                >Filiale</b-th
              >
              <b-th class="th-rouge-bg-color color-white">Régie</b-th>
              <!-- <b-th class="th-rouge-bg-color color-white"
                >Somme de Total Surface à isoler</b-th
              > -->
              <b-th
                class="th-rouge-bg-color  th-border-raduis-rigth color-white"
                >Somme de CA Total</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody v-if="!loading">
            <template v-for="(item, index) in computedGetSuiviRegie"
              ><b-tr :key="index">
                <b-th
                  :rowspan="computedRegieSize(item.value.workers) + 1"
                  class="text-align-left th-peige-bg-color font-size-11 "
                  >{{ item.depot | formatageLabel }}</b-th
                > </b-tr
              ><template v-for="(regie, name) in item.value.workers">
                <b-tr :key="'regie' + name + index">
                  <b-td class="text-align-left th-green-bg-color ">{{
                    name | formatageLabel
                  }}</b-td>
                  <!-- <b-td class="text-align-right th-green-bg-color">{{
                    regie.m2
                  }}</b-td> -->
                  <b-td class="text-align-right th-green-bg-color"
                    >{{ regie.CA }} €
                  </b-td>
                </b-tr>
              </template>
              <b-tr :key="'depot' + index">
                <b-th
                  colspan="2"
                  class="text-align-left th-jaune-bg-color font-size-11 "
                  >Total {{ item.depot | formatageLabel }}</b-th
                >
                <!-- <b-td class="text-align-right th-jaune-bg-color f-w-800 ">{{
                  item.value.totalm2
                }}</b-td> -->
                <b-td class="text-align-right th-jaune-bg-color f-w-800 "
                  >{{ item.value.total }} €
                </b-td>
              </b-tr>
            </template>
            <b-tr v-if="computedGetSuiviRegie.length > 0" class="tr-total">
              <b-th colspan="2" class="text-align-left ">
                Total général
              </b-th>
              <!-- <b-td class=" f-w-800 text-align-right">{{
                computedTotalGeneral.totalm2
              }}</b-td> -->
              <b-td class=" f-w-800 text-align-right"
                >{{ computedTotalGeneral.total }} €</b-td
              >
            </b-tr>

            <b-tr v-if="computedGetSuiviRegie.length == 0">
              <b-td colspan="3">
                Il n'y a aucun enregistrement à afficher
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Récapitulatif-régie-par-zone-Th',
  data() {
    return {
      filterMonth: null,
      filteDepot: { name: 'tous', id: null },
      filteryear: null,
      filterNumeroDepot: null,
      ListYear: [],
      loading: false
    };
  },
  methods: {
    ...mapActions([
      'getRecapRegieFiltredTh',
      'fetchAllDepotRecapTh',
      'exportExcelRecapRegieTh',
      'resetRecapRegieTh',
      'fetchMonthForYearTH',
      'featchDepotForYearTH'
    ]),
    rowClass(item, type) {
      return 'ligneNormale';
    },
    async handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      const response = await this.fetchAllDepotRecapTh({
        num_depot: this.filterNumeroDepot,
        year: this.filteryear,
        month: this.filterMonth
      });
      if (response) {
        if (this.getAllDepotRecapRegieTh.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegieTh();
        }
      }
    },
    async handleChangeNumDepot() {
      const response = await this.fetchAllDepotRecapTh({
        num_depot: this.filterNumeroDepot,
        year: this.filteryear,
        month: this.filterMonth
      });
      if (response) {
        this.filteDepot = { name: 'tous', id: null };

        if (this.getAllDepotRecapRegieTh.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegieTh();
        }
      }
    },
    async handleChangeYear() {
      this.fetchMonthForYearTH(this.filteryear);
      this.featchDepotForYearTH(this.filteryear);
      const response = await this.fetchAllDepotRecapTh({
        num_depot: this.filterNumeroDepot,
        year: this.filteryear,
        month: this.filterMonth
      });
      if (response) {
        this.filteDepot = { name: 'tous', id: null };
        if (this.getAllDepotRecapRegieTh.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegieTh();
        }
      }
    },
    async handleChangeMonth() {
      this.filterNumeroDepot = null;
      const response = await this.fetchAllDepotRecapTh({
        num_depot: this.filterNumeroDepot,
        year: this.filteryear,
        month: this.filterMonth
      });

      if (response) {
        this.filteDepot = { name: 'tous', id: null };
        if (this.getAllDepotRecapRegieTh.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegieTh();
        }
      }
    },
    FilterRecapRegie() {
      this.getRecapRegieFiltredTh({
        num_depot: this.filterNumeroDepot,
        year: this.filteryear,
        month: this.filterMonth,
        depot: this.filteDepot,
        type: 'regie'
      });
    },
    async exportFiles() {
      await this.exportExcelRecapRegieTh({
        num_depot: this.filterNumeroDepot,
        year: this.filteryear,
        month: this.filterMonth,
        depot: this.filteDepot,
        type: 'regie',
        nom_facture: 'RECAP REGIE -'
      });
    }
  },

  computed: {
    ...mapGetters([
      'getLoadingRecapRegieTh',
      'getRecapRegieTh',
      'getAllDepotRecapRegieTh',
      'cantExport',
      'getMonthForYearTh',
      'getAllDepotForYearTh'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedDepot() {
      let tab = [];
      if (this.filterMonth == null) {
        tab = this.getAllDepotForYearTh.map(item => ({
          value: item.depot,
          text: item.depot
        }));
        return tab;
      } else {
        let table = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        if (table.length) {
          tab = table[0].depots.map(item => ({
            value: item.depot,
            text: item.depot
          }));
          return tab;
        } else {
          return [];
        }
      }
    },
    computedGetSuiviRegie() {
      return this.getRecapRegieTh;
    },
    computedgetAllDepotRecapRegieTh() {
      const all_depot = this.getAllDepotRecapRegieTh.map(item => {
        return { id: item, name: item };
      });
      return [{ id: null, name: 'tous' }, ...all_depot];
    },
    computedRegieSize() {
      return function(data) {
        var size = 0,
          key;
        for (key in data) {
          if (data.hasOwnProperty(key)) size++;
        }
        return size;
      };
    },
    computedTotalGeneral() {
      var total = 0;
      var totalm2 = 0;
      for (let i = 0; i < this.getRecapRegieTh.length; i++) {
        total = total + parseFloat(this.getRecapRegieTh[i].value.total);
        totalm2 = totalm2 + parseFloat(this.getRecapRegieTh[i].value.totalm2);
      }
      return { total: total.toFixed(2), totalm2: totalm2 };
    }
  },
  components: {
    SelectComponent: () => import('../../SelectComponent')
  },
  filters: {
    formatageLabel: value => {
      return value.split('_').join(' ');
    }
  },
  async mounted() {
    this.loading = true;
    var courantYear = moment().year();
    this.filteryear = courantYear;
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.fetchMonthForYearTH(this.filteryear);
    this.featchDepotForYearTH(this.filteryear);
    const response = await this.fetchAllDepotRecapTh({
      num_depot: this.filterNumeroDepot,
      year: this.filteryear,
      month: this.filterMonth
    });
    if (response) {
      if (this.getAllDepotRecapRegieTh.length > 0) {
        await this.FilterRecapRegie();
      } else {
        this.resetRecapRegieTh();
      }
    }
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
.contentSuiviRegieRecap {
  padding: 10px !important;
  margin: 0px !important;
  height: calc(100vh - 110px);
  display: block;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.tableRecapRegie {
  max-height: calc(100vh - 190px) !important;
  height: calc(100vh - 191px) !important;
  margin-bottom: 0px;
  td {
    padding: 5px;
  }
}
</style>
<style lang="scss">
.tableRecapRegie {
  .table {
    display: table;
  }
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    padding: 3px 13px !important;
    color: #212529;
  }
  th {
    font-size: 13px !important;
    min-width: 180px;
  }
  td {
    font-size: 10px;
  }
  .text-align-left {
    text-align: left;
  }
  .text-align-right {
    text-align: right;
  }
  .th-rouge-bg-color {
    // background-color: #e9999c !important;
    background-color: #adacca !important;
  }
  .th-jaune-bg-color {
    background-color: #bebcee !important;
  }
  .th-peige-bg-color {
    background-color: #dfdef4 !important;
    // #dcdafe !important
  }
  .th-white-bg-color {
    color: white !important;
    padding: 10px 10px !important;
  }
  .th-green-bg-color {
    background-color: #f0f1ff !important;
  }
  .th-border-raduis-left {
    border-radius: 10px 0px 0px 0px;
  }
  .th-border-raduis-rigth {
    border-radius: 0px 10px 0px 0px;
  }
  .font-size-11 {
    font-size: 11px !important;
  }
  .f-w-800 {
    font-weight: 800;
  }
  .color-white {
    color: white !important;
  }
  .tr-total {
    background-color: #f2f2f2e8;
    font-weight: 800;
    td {
      font-size: 13px;
    }
  }
}
</style>
